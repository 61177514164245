import React from "react";
import "./PrivacyPolicy.css"; 
import Navbar from "./Navbar/Navbar";
import Footer from "./Footer";



const Terms = () => {
  return (
    <div>
      <Navbar/>
    <div className="privacy-policy-container">


      <section className="terms-section">
        <h2>Terms of Use</h2>

        <h3>1. Acceptance of Terms</h3>
        <p>
          By using our services, you confirm that you are at least 18 years old
          or have parental/guardian consent.
        </p>

        <h3>2. Services Provided</h3>
        <p>Our services include, but are not limited to:</p>
        <ul>
          <li>Live courses</li>
          <li>1:1 doubt clearing sessions</li>
          <li>Mock interviews</li>
          <li>Downloadable resources</li>
          <li>Portfolio building</li>
          <li>Blogs and articles</li>
          <li>Module-level assessments</li>
        </ul>

        <h3>3. User Responsibilities</h3>
        <p>
          You agree to use our services only for lawful purposes. You must not:
        </p>
        <ul>
          <li>Transmit harmful or malicious code.</li>
          <li>Attempt unauthorized access to our systems or networks.</li>
          <li>Harass, threaten, or harm other users.</li>
        </ul>

        <h3>4. Intellectual Property</h3>
        <p>
          All content and materials provided through our services, including
          text, graphics, and software, are owned by KorpReady Technologies
           or our licensors. You may not reproduce, distribute, or create
          derivative works without prior written consent.
        </p>

        <h3>5. Termination</h3>
        <p>
          We reserve the right to suspend or terminate your access to our
          services at any time, without prior notice, for any violation of these
          Terms.
        </p>

        <h3>6. Disclaimer of Warranties</h3>
        <p>
          Our services are provided "as-is." We make no warranties or guarantees
          about the accuracy or reliability of our content.
        </p>

        <h3>7. Limitation of Liability</h3>
        <p>
          To the fullest extent permitted by law, KorpReady Technologies
          is not liable for any indirect, incidental, or consequential damages
          arising from your use of our services.
        </p>

        <h3>8. Changes to Terms</h3>
        <p>
          We may modify these Terms of Use at any time. Continued use of our
          services after changes are posted will be deemed acceptance of the
          revised Terms.
        </p>

        {/* <h3>9. Governing Law</h3>
        <p>
          These Terms are governed by the laws of [Your Jurisdiction], without
          regard to conflict of law principles.
        </p> */}

        
      </section>
    </div>
    <Footer/>
    </div>
  );
};

export default Terms;
