import React from "react";
import "./PrivacyPolicy.css"; 
import Navbar from "./Navbar/Navbar";
import Footer from "./Footer";

const RefundPolicy= () => {
  return (
    <div>
      <Navbar/>
    <div className="privacy-policy-container">
      <section className="privacy-section">
        <h2>Refund Policy</h2>
        <p>
        Thank you for choosing KorpReady for your online learning journey.
         Please read our refund policy carefully before making a purchase.
        </p>

        <h3>No Refunds on Courses</h3>
        {/* <h4>Personal Information</h4> */}
        <p>
        As we offer digital courses, we do not provide refunds once a course has been purchased and access has been granted. All sales are final.
        </p>

        <h4>Refund Exceptions</h4>
        <p>
        In rare cases where a refund is applicable, you will receive it within 5-7 working days. The eligibility and confirmation of any refund will be handled by the Korpready Support team assigned to your course.
        </p>

        <h4>Need Help?</h4>
        <p>
        If you experience any issues with accessing your course or have questions about your purchase, please feel free to contact us for assistance.
        </p>

        <h3>How We Use Your Information</h3>
        <p>We may use the information collected to:</p>
        <ul>
          <li>Provide, maintain, and improve our services.</li>
          <li>Process transactions and send you related information.</li>
          <li>Communicate with you about your account, updates, and promotional offers.</li>
          <li>Respond to your inquiries and requests.</li>
          <li>Monitor and analyze usage trends to enhance user experience.</li>
        </ul>

        <h3>Contact Us</h3>
        <p>
        For any inquiries, please email us at: support@korpready.com
        </p>
       

       <h5>Thank you for your understanding and for choosing KorpReady!

</h5>

       

       
      </section>

      
    </div>
    <Footer/>
    </div>
  );
};

export default RefundPolicy
;
