import React from "react";
import "./PrivacyPolicy.css"; 
import Navbar from "./Navbar/Navbar";
import Footer from "./Footer";

const Services= () => {
  return (
    <div>
      <Navbar/>
    <div className="privacy-policy-container">
      <section className="privacy-section">
        <h2>Our Services</h2>
        <p>
        At KorpReady, we offer a comprehensive range of online learning services designed
         to support your educational journey and help you achieve your career goals.
          Here’s what you can expect from our services:
        </p>

        <h3>1. Live Online Courses</h3>
        {/* <h4>Personal Information</h4> */}
        <p>
        Our live courses offer real-time, interactive learning experiences. Led by expert instructors, these sessions allow you to engage directly with the course material, ask questions, and interact with fellow learners, providing a dynamic classroom experience from the comfort of your home.        </p>

        <h4>2. 1:1 Doubt Clearing Sessions</h4>
        <p>
        To ensure you fully understand the course material, we offer 1:1 doubt clearing sessions. These personalized sessions provide you with direct access to instructors, allowing you to address any questions or concerns you have, at your own pace.        </p>

        <h4>3. Mock Interviews</h4>
        <p>
        Prepare for your career with mock interviews tailored to the field you're pursuing. These realistic simulations help you practice your interview skills, receive feedback from industry professionals, and build the confidence you need to succeed in your job search.        </p>

        <h3>4. Downloadable Resources</h3>
        <p>Our courses come with downloadable resources that include slides, reading materials, templates, and tools to reinforce your learning. These resources are designed for easy reference, helping you review key concepts anytime, anywhere.</p>
        

        <h3>5. Portfolio Building</h3>
        <p>
        A strong portfolio can set you apart in the job market. We provide guidance on portfolio building, helping you create a professional collection of your work that highlights your skills, projects, and achievements in a compelling way.        </p>
       
<h3>
6. Industry-Relevant Blogs & Articles
    <p>
    Stay ahead with our regularly updated blogs and articles, covering industry trends, career tips, and learning strategies. These resources offer valuable insights that supplement your coursework and keep you informed about the latest developments in your field.
    </p>
</h3>
<h3>
7. Module-Level Assessments
    <p>
    To track your progress and ensure you're mastering the material, we provide module-level assessments. These quizzes and assignments are designed to test your knowledge, reinforce learning, and help you gauge your improvement throughout the course.
    </p>
</h3>
<h3>Why Choose Our Services?</h3>
       
        <ul>
          <li><storng>Expert-Led Instruction</storng>: Learn from experienced instructors with industry expertise.</li>
          <li><strong>Flexible Learning</strong>: Study at your own pace with access to live sessions and resources.</li>
            <li> <strong>Career Support</strong>: Get personalized guidance for your career growth, from portfolio building to interview preparation.</li>
          <li><strong>Practical Experience</strong>: Build real-world skills with hands-on projects and mock interviews.</li>

        </ul>
       <h5>Thank you for your understanding and for choosing KorpReady!

</h5>

       

       
      </section>

      
    </div>
    <Footer/>
    </div>
  );
};

export default Services
;
