import React from "react";
import "./PrivacyPolicy.css"; 
import Navbar from "./Navbar/Navbar";
import Footer from "./Footer";

const Contactus = () => {
  return (
    <div>
      <Navbar/>
    <div className="privacy-policy-container">


      <section className="terms-section">
        <h2>Contact Us</h2>

        <p>
          If you have questions about these Terms of Use, please contact us at
          <br/>
          phone: {" "}9618948769. <br/>
          Email:{" "}contact@KorpReady.com <br/>
          Address:{" "}H.no.1-98/5/44 & 37, F, Madhapur, Hyderabad, Shaikpet, Telangana, India, 500081
        </p>

      

       

        
      </section>
    </div>
    <Footer/>
    </div>
  );
};

export default Contactus;
