import React from 'react'
import Navbar from '../Navbar/Navbar';
import Footer from '../Footer';
import { Container, Typography } from '@mui/material';
import CourseAccordion from '../Modules';
import Projects from '../Projects';
import "./Hero.css"
import Roadmap from './RoadMap';
import MasterTools from './Master';
import Faqs from './Faqs';
import Hiring from './Hiring';
import axios from 'axios';
import { API_URL } from '../../constants/constants';

const CourseIntro = () => {

  const copyToClipboard = () => {
    // Check if the browser supports the clipboard API
    if (navigator.clipboard) {
      // Get the current URL
      const currentURL = window.location.href;

      // Use the clipboard API to copy the URL to the clipboard
      navigator.clipboard.writeText(currentURL).then(() => {
        alert('Link copied to clipboard!');
      }).catch(err => {
        console.error('Could not copy text: ', err);
      });
    } else {
      // Fallback for browsers that don't support the clipboard API
      alert('Your browser does not support clipboard copying.');
    }
  };
  const courseId = '6714fafb05b50d78c0747c30';
const token=localStorage.getItem("User")
// Endpoint URL
const createOrderUrl = `${API_URL}/api/v1/create-order`;

// Config for the request
const createOrderConfig = {
  headers: {
    'Content-Type': 'application/json',
    'x-auth-token': token,
  },
};

// Payload for creating order
const createOrderPayload = {
  courseId: courseId,
};

// Function to create an order
const createOrder = async () => {
  try {
    const response = await axios.post(createOrderUrl, createOrderPayload, createOrderConfig);
    const { order_id, payment_id, signature } = response.data;
    return { order_id, payment_id, signature }; // Return all needed values
  } catch (error) {
    console.error('Error creating order:', error);
  }
};

const verifyPayment = async (order_id, payment_id, signature) => {
  const verifyPaymentUrl = `${API_URL}/api/v1/verify-payment`

  // Config for the request
  const verifyPaymentConfig = {
    headers: {
      'Content-Type': 'application/json',
      'x-auth-token': token,
    },
  };

  // Payload for verifying payment
  const verifyPaymentPayload = {
    order_id: order_id,
    payment_id: payment_id,
    signature: signature,
  };

  try {
    const response = await axios.post(verifyPaymentUrl, verifyPaymentPayload, verifyPaymentConfig);
    console.log(response.data);
  } catch (error) {
    console.error('Error verifying payment:', error);
  }
};
const paymentHandler = async (e) => {
  e.preventDefault();

  // Create an order to get order_id, payment_id, and signature
  const { order_id, payment_id, signature } = await createOrder();

  var options = {
    key: "rzp_test_BiyQ3fCTbs7hLa",
    name: "Korpready", // Your business name
    description: "Test Transaction",
    image: "https://example.com/your_logo",
    order_id: order_id,
    handler: async function (response) {
      const { razorpay_order_id, razorpay_payment_id, razorpay_signature } = response;

      // Verify payment using these details from Razorpay
      await verifyPayment(razorpay_order_id, razorpay_payment_id, razorpay_signature);
    },
   
    notes: {
      address: "Razorpay Corporate Office",
    },
    theme: {
      color: "#3399cc",
    },
  };
  
  var rzp1 = new window.Razorpay(options);
  rzp1.on("payment.failed", function (response) {
    alert(response.error.code);
    alert(response.error.description);
    alert(response.error.source);
    alert(response.error.step);
    alert(response.error.reason);
    alert(response.error.metadata.order_id);
    alert(response.error.metadata.payment_id);
  });
  rzp1.open();
};
  const handelenroll=(event)=>{
    const token=localStorage.getItem("User")
    if(!token){
      window.location.href = "/login";
    }
    else{
      paymentHandler(event)
    }
  
  }
  return (
    <>
    
    
    <div className='course-intro-m'
    style={{}}
    >

    <div className="course-hero-section">
      <div className="hero-box hero-text">
      <Typography variant="body2" gutterBottom>
               
                <span style={{ color: 'white',}}>
  <a href="/" style={{ color: 'white', textDecoration: 'none' }}>🏠Home</a> {" "}
  &gt; {" "}
  <a href="/courses/details" style={{ color: 'white', textDecoration: 'none' }}>Courses</a> {" "}
  &gt; {" "}
  <a href="/coursedetails" style={{ color: 'white', textDecoration: 'none' }}>Data Science with Gen AI</a>{" "}
</span>

              </Typography>
              <h1 style={{color:"rgb(235 238 242)"}}>Data Science With Gen AI</h1>
        
        <p style={{color:"rgb(235 238 242)"}}>This beginner-friendly course is designed to introduce you to the exciting world of data science. You will learn the basics of Python programming, essential statistics, and introductory concepts in machine learning. Through hands-on exercises, you’ll explore how to analyze data, build  machine learning models, and understand foundational deep learning & NLP  techniques. By the end of this course, you'll have a solid understanding of key concepts in data science, empowering you to pursue further studies or start your career in this dynamic field.</p>
        <div className="courses-details--pricing">
          <p className="courses-details--price">₹ 6999</p>
          <p className="courses-details--discount">₹ 85000</p>
          <p className="courses-details--discount-label" >91% Discount</p>
        </div>
        <div className='d-flex g-2'>
        <button  className='course-button-share' onClick={copyToClipboard}>Share</button>
        <button className='course-button-enroll' onClick={(event)=>{handelenroll(event)}}>Enroll</button>

        </div>

      </div>
      <div className="hero-box hero-image">
        {/* <img src='./images/image-hr-01.png' alt="Learning with iNeuron" /> */}
      </div>
    </div>

    <div className="dt-container">
            <div className="dt-content">
              <div className="dt-item">
                <h3 className="dt-heading"> Live Instructor Led</h3>
                <p className="dt-subheading">Course Type</p>
              </div>
              <div className="dt-item">
                <h3 className="dt-heading">Date</h3>
                <p className="dt-subheading">Start Date</p>
              </div>
              <div className="dt-item">
                <h3 className="dt-heading">Telugu & English</h3>
                <p className="dt-subheading">Language</p>
              </div>
              <div className="dt-item">
                <h3 className="dt-heading">4-5 Month</h3>
                <p className="dt-subheading">Duration</p>
              </div>
              <div className="dt-item">
                <h3 className="dt-heading">7:30 PM,Mon-Sat</h3>
                <p className="dt-subheading">Class Timings</p>
              </div>
            </div>
          </div>

    </div>
    <Container>
    <Roadmap/>
    <MasterTools/>

    <CourseAccordion />
    <Hiring/>
    <Faqs/>
    </Container>

    {/* <Projects /> */}
    
    </>


  )
}

export default CourseIntro;