import React from "react";
import "./PrivacyPolicy.css"; 
import Navbar from "./Navbar/Navbar";
import Footer from "./Footer";

const PrivacyPolicy = () => {
  return (
    <div>
      <Navbar/>
    <div className="privacy-policy-container">
      <section className="privacy-section">
        <h2>Privacy Policy</h2>
        <p>
          At KorpReady Technologies, we are committed to protecting your
          privacy. This Privacy Policy explains how we collect, use, and
          safeguard your information when you use our services, including live
          courses, 1:1 doubt clearing sessions, mock interviews, downloadable
          resources, portfolio building, blogs, and module-level assessments.
        </p>

        <h3>Information We Collect</h3>
        <h4>Personal Information</h4>
        <p>
          We collect personal information you provide when registering for our
          services. This may include your name, email address, phone number, and
          payment information.
        </p>

        <h4>Usage Data</h4>
        <p>
          We may collect information on how you access and use our services,
          such as your IP address, browser type, pages visited, and the time and
          date of your visit.
        </p>

        <h4>Cookies</h4>
        <p>
          We use cookies and similar tracking technologies to monitor activity
          and store certain information. You can configure your browser to
          refuse cookies or notify you when cookies are sent.
        </p>

        <h3>How We Use Your Information</h3>
        <p>We may use the information collected to:</p>
        <ul>
          <li>Provide, maintain, and improve our services.</li>
          <li>Process transactions and send you related information.</li>
          <li>Communicate with you about your account, updates, and promotional offers.</li>
          <li>Respond to your inquiries and requests.</li>
          <li>Monitor and analyze usage trends to enhance user experience.</li>
        </ul>

        <h3>Disclosure of Your Information</h3>
        <p>
          We do not sell or rent your personal information to third parties. We
          may share your information with:
        </p>
        <ul>
          <li>Service providers who assist in operating our services.</li>
          <li>Legal authorities, as required by law or to protect our rights.</li>
        </ul>

        <h3>Data Security</h3>
        <p>
          We implement reasonable measures to protect your personal information
          from unauthorized access, use, or disclosure. However, no internet
          transmission or electronic storage method is completely secure.
        </p>

        <h3>Your Rights</h3>
        <p>
          Depending on your location, you may have the following rights
          regarding your personal information:
        </p>
        <ul>
          <li>The right to access and receive a copy of your data.</li>
          <li>The right to correct inaccurate personal data.</li>
          <li>The right to request deletion of your data.</li>
        </ul>

        <h3>Changes to This Privacy Policy</h3>
        <p>
          We may update this Privacy Policy from time to time. We will notify
          you of changes by posting the new version on our website.
        </p>

        <h3>Contact Us</h3>
        <p>
          If you have questions about this Privacy Policy, please contact us at
          support@Korpready.com.
        </p>
      </section>

      
    </div>
    <Footer/>
    </div>
  );
};

export default PrivacyPolicy;
